<template>
  <b-sidebar
    id="channel-list-sidebar"
    :visible="isChannelWeightSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="initValues"
    @change="(val) => $emit('update:is-channel-weight-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Channel Group
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <b-form class="p-2">
        <!-- Channels -->
        <b-form-group
          label="Channels"
          label-for="channels"
        >
          <v-select
            v-model="cid"
            label="cname"
            :options="channelOptions"
            :reduce="val => val.cid"
            :clearable="false"
            :searchable="false"
            input-id="channels"
            :disabled="!isNew"
          />
        </b-form-group>

        <!-- Initial Weight -->
        <b-form-group
          label="Initial Weight"
          label-for="initial-weight"
        >
          <b-form-input
            id="initial-weight"
            v-model="initialWeight"
            autofocus
            trim
          />
        </b-form-group>

        <!-- Step -->
        <b-form-group
          label="Step"
          label-for="step"
        >
          <b-form-input
            id="step"
            v-model="step"
            autofocus
            trim
          />
        </b-form-group>

        <!-- Weight -->
        <b-form-group
          label="Weight"
          label-for="weight"
        >
          <b-form-input
            id="weight"
            v-model="weight"
            autofocus
            trim
          />
        </b-form-group>

        <!-- mandatory -->
        <b-form-group
          label="Mandatory"
          label-for="mandatory"
        >
          <v-select
            v-model="mandatory"
            :options="mandatoryOptions"
            :reduce="val => val.value"
            :clearable="false"
            :searchable="false"
            input-id="mandatory"
          />
        </b-form-group>

        <!-- Backup -->
        <b-form-group
          label="Use For Backup"
          label-for="backup"
        >
          <v-select
            v-model="backup"
            :options="mandatoryOptions"
            :reduce="val => val.value"
            :clearable="false"
            :searchable="false"
            input-id="backup"
          />
        </b-form-group>

        <!-- Backup Priority -->
        <b-form-group
          v-if="backup"
          label="Backup Priority"
          label-for="backup_priority"
        >
          <b-form-input
            id="backup_priority"
            v-model="backupPriority"
            autofocus
            trim
          />
        </b-form-group>

        <!-- status -->
        <b-form-group
          label="Status"
          label-for="status"
        >
          <v-select
            v-model="status"
            :options="statusOptions"
            :reduce="val => val.value"
            :clearable="false"
            :searchable="false"
            input-id="default"
          />
        </b-form-group>

        <el-divider class="mt-3 mb-3" />

        <div class="d-flex mt-2">
          <b-button
            variant="primary"
            class="mr-2"
            :disabled="!isSubmitValid"
            type="submit"
            @click.prevent="confirmPopup"
          >
            Submit
          </b-button>
          <b-button
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            Cancel
          </b-button>
        </div>

      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BFormGroup, BFormInput, BButton, BForm,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { showAlert } from '@/libs/tool'
import useChannelsJs from './channels'

const {
  createChannelWeight,
  updateChannelWeight,
} = useChannelsJs()

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
  },
  model: {
    prop: 'isChannelWeightSidebarActive',
    event: 'update:is-channel-weight-sidebar-active',
  },
  props: {
    isChannelWeightSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    channelOptions: {
      type: Array,
      required: true,
    },
    groupId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isNew: false,
      cid: '',
      initialWeight: 0,
      step: 1,
      weight: 0,
      mandatory: false,
      mandatoryOptions: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
      backup: false,
      backupPriority: 1,
      status: 1,
      statusOptions: [
        { label: 'Enable', value: 1 },
        { label: 'Disable', value: 0 },
      ],

    }
  },
  computed: {
    isSubmitValid() {
      let valid = false
      if (this.cid && this.cid !== ''
          && this.initialWeight >= 0
          && this.step > 0
          && this.weight >= 0
      ) {
        if (this.backup) {
          if (this.backupPriority > 0) {
            valid = true
          }
        } else {
          valid = true
        }
      }
      return valid
    },
  },
  watch: {
    isChannelWeightSidebarActive(val) {
      this.isNew = this.data.is_new
      if (!val || this.data.is_new) {
        this.initValues()
        return
      }
      this.initChannelWeightInfo(this.data)
    },
  },
  methods: {
    initChannelWeightInfo(info) {
      this.cid = info.cid
      this.initialWeight = info.initial_weight
      this.step = info.step
      this.weight = info.weight
      this.mandatory = info.mandatory
      this.backupPriority = info.backup_priority
      this.backup = this.backupPriority > 0
      this.status = info.status
    },
    initValues() {
      this.cid = ''
      this.initialWeight = 0
      this.step = 1
      this.weight = 0
      this.mandatory = false
      this.backup = false
      this.backupPriority = 0
      this.status = 1
    },
    confirmPopup() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.save()
        }
      })
    },
    save() {
      this.backupPriority = this.backup ? this.backupPriority : 0
      const obj = {
        group_id: this.groupId,
        cid: this.cid,
        initial_weight: this.initialWeight,
        step: this.step,
        weight: this.weight,
        mandatory: this.mandatory,
        backup_priority: this.backupPriority > 0 ? this.backupPriority : 0,
        status: this.status,
      }
      if (this.isNew) {
        createChannelWeight(
          obj,
          () => {
            showAlert(this, 'success', 'Updated!', 'Success')
            this.$emit('update:is-channel-weight-sidebar-active', false)
            this.$emit('updated')
          },
          fail => {
            showAlert(this, 'warning', 'Fail!', `${fail}`)
          },
        )
      } else {
        updateChannelWeight(
          obj,
          () => {
            showAlert(this, 'success', 'Updated!', 'Success')
            this.$emit('update:is-channel-weight-sidebar-active', false)
            this.$emit('updated')
          },
          fail => {
            showAlert(this, 'warning', 'Fail!', `${fail}`)
          },
        )
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
