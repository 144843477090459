<template>
  <div>
    <!-- Channel Group Sidebar -->
    <channel-group-weights-sidebar
      :is-channel-weight-sidebar-active.sync="isChannelWeightsSidebarActive"
      :group-id="groupId"
      :channel-options="channelOptions"
      :data="channelWeightInfo"
      @updated="queryChannelGroupWeights"
    />

    <!-- Table Container Card -->
    <b-card no-body>
      <div class="m-2">
        <b-button
          variant="outline-primary"
          :to="{ name: 'channel-group' }"
        >
          <feather-icon
            icon="ArrowLeftIcon"
            class="mr-50"
          />
          <span class="align-middle">Back</span>
        </b-button>

        <b-button
          class="ml-2"
          variant="outline-primary"
          @click="addNewChannel"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">Add New</span>
        </b-button>
      </div>

      <b-table
        class="position-relative"
        responsive
        :fields="tableFields"
        :items="channelWeights"
        primary-key="cid"
        show-empty
        striped
        :borderless="false"
        empty-text="No matching records found"
      >
        <!-- Column: Name -->
        <template #cell(channel)="data">
          <b-media vertical-align="center">
            <b-link
              class="font-weight-bold d-block"
            >
              {{ data.item.cname }}
            </b-link>
            <small class="text-muted">@{{ data.item.cid }}</small>
          </b-media>
        </template>

        <!-- Column: Weight -->
        <template #cell(weight)="data">
          {{ data.item.weight }} {{ formatWeightPercentage(data.item.weight) }}
        </template>

        <!-- Column: Mandatory -->
        <template #cell(mandatory)="data">
          <b-badge
            pill
            :variant="`light-${resolveDefaultVariant(data.item.mandatory)}`"
            class="ml-1"
          >
            <feather-icon
              :icon="`${resolveDefaultIcon(data.item.mandatory)}`"
            />
          </b-badge>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveDefaultVariant(data.item.status)}`"
            class="ml-1"
          >
            {{ resolveStatusText(data.item.status) }}
          </b-badge>
        </template>

        <!-- Column: Backup Priority -->
        <template #cell(backup_priority)="data">
          {{ data.item.backup_priority === 0 ? 'N/A' : data.item.backup_priority }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap ml-2">
            <feather-icon
              icon="EditIcon"
              class="cursor-pointer ml-1"
              size="16"
              @click="showDetail(data.item)"
            />
            <feather-icon
              icon="Trash2Icon"
              class="cursor-pointer ml-1"
              size="16"
              @click="deleteConfirm(data.item)"
            />
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BLink, BButton, VBModal, VBTooltip, BMedia, BBadge,
} from 'bootstrap-vue'
import { showAlert } from '@/libs/tool'
import useChannelsJs from './channels'
import ChannelGroupWeightsSidebar from './ChannelGroupWeightsSidebar.vue'

const {
  fetchChannelGroupWeights,
  fetchChannels,
  removeChannelWeight,
} = useChannelsJs()

export default {
  components: {
    ChannelGroupWeightsSidebar,

    BCard,
    BTable,
    BLink,
    BButton,
    BMedia,
    BBadge,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      groupId: this.$route.params.group_id,
      region: this.$route.query.region,
      method: this.$route.query.method,
      channelWeights: [],
      totalWeights: 0,
      tableFields: [
        'channel',
        { key: 'weight', label: 'Weight' },
        'mandatory',
        { key: 'backup_priority', label: 'Backup Weight/Priority' },
        'status',
        'actions',
      ],
      isChannelWeightsSidebarActive: false,
      channelWeightInfo: {},
      channelOptions: [],
    }
  },
  created() {
    fetchChannels(
      channels => {
        this.channelOptions = channels.filter(item => item.region_code === this.region && item.type_name === this.method && item.status === 1)
      },
      fail => {
        showAlert(this, 'warning', 'Fail!', `${fail}`)
      },
    )

    this.queryChannelGroupWeights()
  },
  methods: {
    resolveDefaultVariant(val) {
      if (val) return 'success'
      if (!val) return 'secondary'
      return 'warning'
    },
    resolveDefaultIcon(val) {
      if (val) return 'CheckIcon'
      if (!val) return 'XIcon'
      return 'XIcon'
    },
    resolveStatusVariant(status) {
      if (status === 0) return 'warning'
      if (status === 1) return 'success'
      return 'secondary'
    },
    resolveStatusText(status) {
      if (status === 0) return 'Disabled'
      if (status === 1) return 'Enabled'
      return 'Unknown'
    },
    formatWeightPercentage(weight) {
      if (this.totalWeights === 0) {
        return ''
      }
      const per = parseInt(parseFloat(weight / this.totalWeights).toFixed(2) * 100, 0)
      return `(${per}%)`
    },
    queryChannelGroupWeights() {
      fetchChannelGroupWeights(
        this.groupId,
        channelWeights => {
          this.totalWeights = channelWeights.reduce((total, weight) => total + weight.weight, 0)
          this.channelWeights = channelWeights.sort((a, b) => b.weight - a.weight)
        },
        fail => {
          showAlert(this, 'warning', 'Fail!', `${fail}`)
        },
      )
    },
    addNewChannel() {
      this.channelWeightInfo = {
        is_new: true,
        group_id: this.groupId,
      }
      this.isChannelWeightsSidebarActive = true
    },
    deleteConfirm(weightInfo) {
      this.$swal({
        title: 'Are you sure?',
        text: `Group [${weightInfo.cname}] will be deleted!`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteChannelWeight(weightInfo)
        }
      })
    },
    deleteChannelWeight(weightInfo) {
      removeChannelWeight(
        weightInfo,
        () => {
          showAlert(this, 'success', 'Success!', 'Deleted.')
          this.queryChannelGroupWeights()
        },
        fail => {
          showAlert(this, 'warning', 'Fail!', `${fail}`)
        },
      )
    },
    showDetail(weightInfo) {
      this.channelWeightInfo = weightInfo
      this.isChannelWeightsSidebarActive = true
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
